import axios from 'axios';
import buildPaginationQueryOpts from '@/shared/sort/sorts';
import { ISetting, Setting } from '@/shared/model/setting.model';
import { ISsoMapper } from '@/shared/model/sso-mapper.model';
import { ISsoProvision } from '@/shared/model/sso-provision.model';

const baseApiUrl = 'api/private/settings';

export default class SettingService {
  public find(id: number): Promise<ISetting> {
    return new Promise<ISetting>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public retrieve(paginationQuery?: any): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `?${buildPaginationQueryOpts(paginationQuery)}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public create(entity: ISetting): Promise<ISetting> {
    return new Promise<ISetting>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public update(entity: ISetting): Promise<ISetting> {
    return new Promise<ISetting>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}`, entity)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public updateOrgSetting(id: Number, entity: ISetting): Promise<ISetting> {
    return new Promise<ISetting>((resolve, reject) => {
      axios
        .put(`api/private/organizations/${id}/settings`, entity)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public createOrgSetting(id: Number, entity: ISetting): Promise<ISetting> {
    return new Promise<ISetting>((resolve, reject) => {
      axios
        .post(`api/private/organizations/${id}/settings`, entity)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public deleteOrgSetting(orgId: number, settingId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`api/private/organizations/${orgId}/settings/${settingId}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public setDefaultSSO(orgId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`api/private/organizations/${orgId}/settings/default/sso`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public createLDOfflineSetting(orgId: number): Promise<ISetting>{
    const setting: ISetting = new Setting();
    setting.key = 'ld-offline';
    setting.value = 'true';
    setting.defaultSetting = false;
    setting.type = 'boolean';
    setting.displayValue = 'Offline';
    setting.description = 'Lectora Desktop offline';

    return this.createOrgSetting(orgId, setting);
  }

  public changeLogo(id: number, file: any) {
    const options = {
      headers: {'Content-Type': 'multipart/form-data'}
    };
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/${id}/logo`, file, options)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public retrieveSsoMapper(settingId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `/${settingId}/sso-mapper`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public createSsoMapper(settingId: number, entity: ISsoMapper): Promise<ISsoMapper> {
    return new Promise<ISsoMapper>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/${settingId}/sso-mapper`, entity)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public updateSsoMapper(settingId: number, entity: ISsoMapper): Promise<ISsoMapper> {
    return new Promise<ISsoMapper>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${settingId}/sso-mapper`, entity)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public deleteSsoMapper(settingId: number,id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${settingId}/sso-mapper/${id}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public retrieveSsoProvision(settingId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(baseApiUrl + `/${settingId}/sso-provision`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public createSsoProvision(settingId: number, entity: ISsoProvision): Promise<ISsoProvision> {
    return new Promise<ISsoProvision>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/${settingId}/sso-provision`, entity)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public updateSsoProvision(settingId: number, entity: ISsoProvision): Promise<ISsoProvision> {
    return new Promise<ISsoMapper>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${settingId}/sso-provision`, entity)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public deleteSsoProvision(settingId: number,id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${settingId}/sso-provision/${id}`)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public generateScimToken(settingId: number, entity: ISsoProvision): Promise<ISsoProvision> {
    return new Promise<ISsoProvision>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/${settingId}/generate-token`, entity)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  public deleleAllSsoProvision(settingId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
    this.retrieveSsoProvision(settingId)
    .then((res) => {
      res.data.forEach((provision) => {
          this.deleteSsoProvision(settingId, provision.id);
        });
        resolve(true);
      }).catch((err) => {
        reject(err);
      });
    });
  }

  public deleteAllSsoMappers(settingId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.retrieveSsoMapper(settingId)
      .then((res) => {
        res.data.forEach((mapper) => {
          this.deleteSsoMapper(settingId, mapper.id); 
        });
        resolve(true);
      }).catch((err) => {
        reject(err);
      });
    });
  }
}
